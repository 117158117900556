import React from 'react';
import Layout from '../components/layout';

const headMeta = {
	title: 'Sitemap | Pandev Law',
	description: 'Sitemap',
	keywords: 'Sitemap',
};

const schema = `{}`;

function Sitemap({ location }) {
	return (
		<Layout headMeta={headMeta} location={location} footerSchema={schema}>
			<div className="section pt-5 pb-5 pt-md-10 pb-md-10">
				<div className="container">
					<div className="row">
						<div className="offset-lg-2 col-lg-8">
							<h1 className="text-mega mb-8">Sitemap</h1>
							<ul className="list-bullets">
								<li>
									<a className="link" href="/">
										Immigration Lawyers USA
									</a>
								</li>
								<li>
									<a className="link" href="/about/">
										About Pandev Law
									</a>
								</li>
								<li>
									<a className="link" href="/business-law-services/">
										Business Law Services
									</a>
								</li>
								<li>
									<a
										className="link"
										href="/family-based-green-card-immigration/">
										Family-Based Immigration USA
									</a>
								</li>
								<li>
									<a
										className="link"
										href="/investor-visa-usa-immigration/">
										Investment Immigration USA
									</a>
								</li>
								<li>
									<a className="link" href="/private-client-services/">
										Private Client Services
									</a>
								</li>
								<li>
									<a className="link" href="/eb-5-visa/">
										EB-5 Visa USA Services
									</a>
								</li>
								<li>
									<a className="link" href="/e-2-visa/">
										E2 Visa USA Services
									</a>
								</li>
								<li>
									<a className="link" href="/l-1-visa/">
										L-1 Visa Lawyer Services
									</a>
								</li>
								<li>
									<a
										className="link"
										href="/startup-lawyer-for-business-startups/">
										Startup Lawyer
									</a>
								</li>
								<li>
									<a className="link" href="/general-counsel-services/">
										General Counsel Services
									</a>
								</li>
								<li>
									<a
										className="link"
										href="/intellectual-property-legal-services/">
										Intellectual Property Legal Services
									</a>
								</li>
								<li>
									<a
										className="link"
										href="/individual-immigration-services/">
										Individual Immigration Services
									</a>
								</li>

								<li>
									<a
										className="link"
										href="/employment-based-visa-immigration/">
										Employment-Based Immigrant Visa USA
									</a>
								</li>
								<li>
									<a className="link" href="/our-clients/">
										Pandev Law Clients
									</a>
								</li>
								<li>
									<a className="link" href="/success-stories/">
										Success stories
									</a>
								</li>
								<li>
									<a className="link" href="/offices/">
										Offices
									</a>
								</li>
								<li>
									<a className="link" href="/contact/">
										Contact
									</a>
								</li>
								<li>
									<a
										className="link"
										href="/new-york-immigration-lawyer/">
										New York Immigration Lawyer Midtown USA
									</a>
								</li>
								<li>
									<a
										className="link"
										href="/new-york-immigration-lawyer-downtown/">
										New York Immigration Lawyer Downtown USA
									</a>
								</li>
								<li>
									<a
										className="link"
										href="/charleston-immigration-lawyer/">
										Charleston Immigration Lawyer USA
									</a>
								</li>
								<li>
									<a className="link" href="/advertising-disclaimers/">
										Advertising &amp; Disclaimers
									</a>
								</li>
								<li>
									<a className="link" href="/blog/">
										Immigration Law Services Blog
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Sitemap;
